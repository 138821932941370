const abbreviationDictionary: {
  [type: string]: Abbreviation[];
} = {
  inspectedProducts: [
    {
      key: 'O',
      value: 'liczba, suma skontrolowanych wyrobów',
    },
    {
      key: 'P',
      value: 'liczba skontrolowanych wyrobów dla kontroli planowych',
    },
    {
      key: 'D',
      value: 'liczba skontrolowanych wyrobów dla kontroli doraźnych',
    },
    {
      key: 'BR',
      value:
        'liczba skontrolowanych wyrobów dla kontroli w trybie bez rekontroli',
    },
    {
      key: 'R',
      value:
        'liczba skontrolowanych wyrobów dla kontroli sprawdzających (rekontroli)',
    },
  ],
  inspectedProductsByMisstatement: [
    {
      key: 'BO',
      value: 'wszystkie skontrolowane wyroby z brakiem wymaganego oznakowania',
    },
    {
      key: 'ON',
      value: 'wszystkie skontrolowane wyroby z nielegalnym oznakowaniem',
    },
    {
      key: 'NO',
      value: 'wszystkie skontrolowane wyroby z niewłaściwym oznakowaniem',
    },
    {
      key: 'BI',
      value: 'wszystkie skontrolowane wyroby z brakiem obowiązkowej informacji',
    },
    {
      key: 'NI',
      value:
        'wszystkie skontrolowane wyroby z niewłaściwą, obowiązkową informacją',
    },
    {
      key: 'BZ',
      value:
        'wszystkie skontrolowane wyroby z brakiem deklaracji właściwości użytkowych lub krajowej deklaracji właściwości użytkowych',
    },
    {
      key: 'ND',
      value: 'wszystkie skontrolowane wyroby z nieprawidłową deklaracją',
    },
    {
      key: 'BS',
      value:
        'wszystkie skontrolowane wyroby z brakiem lub wskazaniem nieprawidłowej specyfikacji technicznej',
    },
    {
      key: 'BWU',
      value:
        'wszystkie skontrolowane wyroby z brakiem lub wskazaniem niewłaściwych właściwości użytkowych',
    },
    {
      key: 'BT',
      value: 'wszystkie skontrolowane wyroby z brakiem właściwych badań',
    },
    {
      key: 'NZKP',
      value:
        'wszystkie skontrolowane wyroby z brakiem lub nieprawidłową zakładową kontrolą produkcji',
    },
    {
      key: 'NCZKP',
      value:
        'wszystkie skontrolowane wyroby z brakiem wymaganego certyfikatu ZKP',
    },
    {
      key: 'NCSWU',
      value:
        'wszystkie skontrolowane wyroby z brakiem certyfikatu stałości i weryfikacji właściwości użytkowych',
    },
    {
      key: 'IN',
      value:
        'wszystkie skontrolowane wyroby z innymi nieprawidłowościami dotyczącymi dokumentacji',
    },
    {
      key: 'N',
      value: 'liczba skontrolowanych, niezgodnych wyrobów',
    },
    {
      key: 'NCPR',
      value:
        'liczba skontrolowanych, niezgodnych wyrobów podlegających przepisom CPR',
    },
  ],
  inspectedProductsByRange: [
    {
      key: 'O',
      value: 'liczba wszystkich skontrolowanych wyrobów',
    },
    {
      key: 'N',
      value: 'liczba skontrolowanych, niezgodnych wyrobów',
    },
    {
      key: 'CPR',
      value: 'liczba skontrolowanych wyrobów podlegających przepisom CPR',
    },
    {
      key: 'NCPR',
      value:
        'liczba skontrolowanych, niezgodnych wyrobów podlegających przepisom CPR',
    },
  ],
  samples: [
    {
      key: '25.1',
      value: 'próbki pobrane na podstawie art. 25.1 UOWB',
    },
    {
      key: '16.2a',
      value: 'próbki pobrane na podstawie art. 16 ust. 2a UOWB',
    },
    {
      key: 'Pp',
      value: 'próbki pobrane na podstawie art. 48 ust. 5 Prawa Przedsiębiorców',
    },
    {
      key: 'S',
      value: 'próbki pobrane u sprzedawcy',
    },
    {
      key: 'B',
      value: 'próbki pobrane na budowie',
    },
    {
      key: 'P',
      value: 'próbki z wynikami pozytywnymi',
    },
    {
      key: 'N',
      value: 'próbki z wynikami negatywnymi',
    },
    {
      key: 'CPR',
      value:
        'suma kwot kosztów Skarbu Państwa z próbek dotyczących wyrobów oznaczonych atrybutem CPR (305/2011)',
    },
    {
      key: 'PW',
      value:
        'suma kwot kosztów Skarbu Państwa z próbek dotyczących wyrobów pozostałych',
    },
  ],
  proceeding: [
    {
      key: '16.1a',
      value:
        'prowadzone postępowania administracyjne na podstawie art. 16.1a UOWB',
    },
    {
      key: '32a',
      value:
        'prowadzone postępowania administracyjne na podstawie art. 32a UOWB',
    },
    {
      key: 'KP',
      value:
        'prowadzone postępowania administracyjne w sprawie nałożenia kary pieniężnej',
    },
    {
      key: '>RS',
      value: 'wszczęte postępowania przed okresem sprawozdawczym',
    },
    {
      key: 'RS',
      value: 'wszczęte postępowania w okresie sprawozdawczym',
    },
    {
      key: 'O',
      value: 'ogółem liczba prowadzonych postępowań w okresie sprawozdawczym',
    },
    {
      key: 'ZRS',
      value: 'liczba zakończonych postępowań w okresie sprawozdawczym',
    },
    {
      key: 'TRS',
      value:
        'liczba niezakończonych (nadal w toku) postępowań na koniec okresu sprawozdawczego',
    },
  ],
  other: [
    {
      key: '22.1',
      value: 'orzeczenia wydane na podstawie art. 22.1 UOWB',
    },
    {
      key: '22.3',
      value: 'orzeczenia wydane na podstawie art. 22.3 UOWB',
    },
    {
      key: '22c.1',
      value: 'orzeczenia wydane na podstawie art. 22c.1 UOWB',
    },
    {
      key: '22c.4',
      value: 'orzeczenia wydane na podstawie art. 22c.4 UOWB',
    },
    {
      key: 'art. 26 ust. 4',
      value: ' orzeczenia wydane na podstawie art. 26.4 UOWB',
    },
    {
      key: 'O',
      value: 'ogółem wydane orzeczenia ze wskazanej podstawy prawnej',
    },
    {
      key: 'CPR',
      value: 'w tym dotyczące wyrobów oznaczonych atrybutem CPR (305/2011)',
    },
  ],
};

export type Abbreviation = {
  key: string;
  value: string;
};

export default (
  type: string,
  abbrs: string[] | null = null,
): Abbreviation[] => {
  if (abbrs) {
    const array: Abbreviation[] = [];
    abbrs.forEach(abbr => {
      const item = abbreviationDictionary[type].find(item => item.key === abbr);
      if (item) {
        array.push(item);
      }
    });
    return array;
  } else {
    return abbreviationDictionary[type];
  }
};
